import React from 'react'
import Head from '../components-en/head'
import Layout from '../components-en/layout'
import '../styles/grid.scss'
import '../styles/index.scss'
import hosGeldin from "../images/hosgeldin.svg"
//import queryString from "query-string"

const VerifyPage = ({ location }) => {
/*
const queryParams = queryString.parse(location.search)
const verificationCode = queryParams.code
const userId = queryParams.id

const verificationObj = {
    "userId": userId,
    "verificationCode": verificationCode
}

const verifyUser = (e) => {
    e.preventDefault()
    fetch('https://api.cember.app/api/user/verify', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(verificationObj)
    })
    .then((res) => {
        console.log(res)
    })
    .catch((error) => alert(error))
    }

verifyUser()
*/

return (
<Layout>
<Head title="Verify Çember Account" description="Verification page for Çember account." />
<div className="row invitation">
    <div className="hero-container">
    <div className="container">
    <div className="col-6">
    
        <h1>Welcome to Çember</h1>    
        <p>Your account has been verified. You can go to app and login.</p>

    </div>
    <div className="col-6">
    <img src={hosGeldin} alt="Welcome" />

    </div>
    </div>
</div>
</div>
    </Layout>

    
)
    
    
}

export default VerifyPage